/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/lodash@4.17.10/lodash.min.js
 * - /npm/prepare-transition@0.1.1/preparetransition.min.js
 * - /npm/flexslider@2.7.0/jquery.flexslider.min.js
 * - /npm/fitvids.1.1.0@1.1.0/jquery.fitvids.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/packery@2.1.1/dist/packery.pkgd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
